.footer {
  background-color: #222;
  color: #f8f9fa;
}

.footer-logo {
  max-width: 150px;
}
.footer ul li {
  font-family: "Space Grotesk";
}

.footer h5 {
  margin-bottom: 1rem;
  font-weight: bold;
  font-family: "Space Grotesk";
}

.footer a {
  color: #f8f9fa;
  text-decoration: none;
  font-family: "Space Grotesk";
}

.footer a:hover {
  color: #ffc107; /* Yellow on hover */
}

.social-icons {
  transition: color 0.3s ease;
  display: flex;
  justify-content: flex-start;
}

.social-icon {
  color: #ffffff; /* Default white color */
  transition: color 0.3s ease; /* Smooth transition for hover */
}

.social-icon:hover {
  color: #ffc107 !important; /* Yellow color on hover */
}

.footer-bottom a{
  transition: color 0.3s ease, color 0.3s ease;
}
.footer-bottom a:hover {
  color: black !important;
}

@media (max-width: 576px) {
  .footer .footer-bottom {
    text-align: center;
  }

  .footer .social-icons {
    justify-content: center; /* Center icons on small screens */
  }
}
