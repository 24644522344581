/* General layout */
.all-products-page {
  display: flex;
  padding: 20px;
}
.filters {
  padding-right: 20px;
}
/* .filter-sidebar {
} */

/* Remove the grid display properties from here */
.products-grid {
  width: 100%;
  padding-left: 20px;
  /* No need for display: grid, Bootstrap will handle this */
}

/* Filters Styling */
.filter-group {
  margin-bottom: 30px;
}

.filter-group h3 {
  margin-bottom: 10px;
  color: #141414;
  font-size: 16;
  font-weight: 400;
  font-family: "Space Grotesk";
}

.filter-group ul {
  list-style-type: none;
  padding: 0;
}

.filter-group ul li {
  margin-bottom: 10px;
}

.filter-group ul li label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Custom radio button styling */
.custom-radio {
  display: none;
}

.category-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
  position: relative;
}

.category-label::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  transition: all 0.3s ease;
}

.custom-radio:checked + .category-label::before {
  background-color: rgba(255, 187, 0, 1);
}
.custom-radio:checked + .category-label {
  color: rgba(255, 187, 0, 1);
}

.filter-group a {
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

/* Price Range */
/* Range Slider Styling */
/* Range Slider Wrapper */
.slider-wrapper {
  position: relative;
  margin-top: 22px;
  height: 40px; /* Add some height to avoid overlap */
}

/* Range Slider Base (for minPrice) */
.price-slider {
  position: absolute;
  height: 10px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  background: #7f7f7f;
  z-index: 5; /* Place the minPrice slider behind */
  pointer-events: none; /* Ensure the maxPrice slider is on top */
}

.price-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #7f7f7f;
  cursor: pointer;
  pointer-events: auto; /* Allow interactions with the thumb */
}

.price-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto; /* Allow interactions with the thumb */
}

/* Second Slider (for maxPrice) */
.price-slider-max {
  position: absolute;
  background: #7f7f7f;
  height: 10px;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  z-index: 3; /* Place maxPrice slider on top */
}

.price-slider-max::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #7f7f7f;
  cursor: pointer;
}

.price-slider-max::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #7f8c8d;
  cursor: pointer;
}

/* Input Fields for Price Display */
.price-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.price-inputs input {
  width: 70px;
  height: 30px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #f9f9f9;
  font-size: 14px;
  margin: 0 10px;
}

.price-inputs span {
  font-size: 20px;
}

.price-range {
  margin-top: 20px;
  font-size: 18px;
}

/* Color Filter Styling */
.color-options {
  display: flex;
  flex-direction: column;
}

.color-options li {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.color-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  border: 1px solid #ddd;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}
.color-box.active {
  box-shadow: 0 0 0 3px #ebebeb; /* Yellow border with a distance */
  transform: scale(1); /* Yellow border */
}
/* Product Card */
.product-card {
  padding: 15px;
  text-align: center;
  background-color: #fff;
}

.product-card img {
  width: 100%;
  height: 200px;
  margin-bottom: 15px;
}

.product-card h5 {
  color: #141414;
  font-size: 16px;
  font-family: "Space Grotesk";
  font-weight: 400;
  word-break: break-word;
}

.product-card p {
  font-size: 16px;
  color: #888;
}
.product-card .colors {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  gap: 10px; /* Add space between the circles */
  margin-top: 10px;
}
.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 5px;
   display: inline-block;
  border: 2px white solid; /* Default no border */
  cursor: pointer;
   -webkit-transform: none;
  transform: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.color-circle.active {
  box-shadow: 0 0 0 2px #f1c40f; /* Yellow border with a distance */
  transform: scale(1);
}
/* No Products Found Styling */
.no-products {
  text-align: center;
  margin-top: 50px;
  color: #888;
}

.no-products h4 {
  font-size: 20px;
  color: #444;
}
.filter-modal-close {
  display: none;
 
}
/* Filter Header and Arrow */
.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.filter-header h3 {
  margin: 0;
  font-size: 16px;
}

.toggle-arrow {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.toggle-arrow.up {
  transform: rotate(180deg); /* Rotate the arrow up */
}

/* Filter Content Sliding Behavior */
.filter-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.filter-content.open {
  max-height: 400px; /* Adjust based on content size */
}

.filter-content.collapsed {
  max-height: 0;
}

/* Responsive adjustments */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .color-circle {
    width: 20px;
    height: 20px;
  }
}
/* Responsive behavior: Apply toggle only on mobile */
@media (min-width: 992px) {
  .filter-content {
    max-height: none;
    overflow: visible;
    transition: none;
  }

  .toggle-arrow {
    display: none; /* No arrow in desktop view */
  }
}

@media (max-width: 992px) {
  /* Sidebar takes full width on smaller screens */
  .filter-sidebar {
    width: 100%;
  }

  /* Stack the layout vertically on smaller screens */
  .all-products-page {
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px) {
  .filters {
    display: none;
  }
  .filter-modal-close {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    align-items: center;
  }
  .products-grid {
    width: 100%;
  }
}
@media only screen and (min-width:992px) and (max-width:1024px) {
  .all-products-page {
    flex-direction: row;
  }
}


/* Mobile: Filters hidden initially, shown on button click */
@media (max-width: 991px) {
  .filter-sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%; /* Ensure it takes full height */
    width: 100%; /* Ensure it takes full width */
    background-color: white;
    z-index: 1000;
    transform: translateY(100%); /* Hidden by default */
    transition: transform 0.4s ease-in-out;
    padding: 20px;
    overflow-y: auto; /* Allow scrolling if the filter content exceeds screen height */
  }

  .filter-sidebar.visible {
    transform: translateY(0); /* Slide up to show */
  }

  .btn-filter {
    display: block;
    margin-bottom: 20px;
    text-align: left;
  }

  /* Overlay to darken the background */
  .filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: none;
  }

  .filter-overlay.visible {
    display: block; /* Show overlay when modal is visible */
  }
}
/* @media (max-width: 991px) {
  .filters {
    display: none;
  }
} */

@media (min-width: 992px) {
  .filters {
    display: block;
  }
  .apply-filter{
    display: none;
  }
 
}
/* Always open filter content on desktop (non-mobile) */
@media (min-width: 993px) {
  .filter-content {
    max-height: none !important;
    display: block !important;
  }
}

